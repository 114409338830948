module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gröem Official | Professional Beauty Tools for You","short_name":"Gröem Official","start_url":"/","background_color":"#ffffff","theme_color":"#f3818181","display":"fullscreen","icon":"content/favicon.png","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5d283968c0b24fa1f88c6e8b800dcea"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1540,"linkImagesToOriginal":false,"showCaptions":true,"quality":80,"withWebp":true,"tracedSVG":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Gröem Official | Professional Beauty Tools for You: CMS","htmlFavicon":"content/favicon.png","modulePath":"/opt/build/repo/node_modules/@marscollective/gatsby-theme-link-free/src/cms","manualInit":true},
    }]
